import React from "react";
import EuroSection from "../components/EuroSection";

import { Helmet } from "react-helmet";

export default function FunduszeEuropejskie() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fundusze Europejskie | Stragona</title>
        <meta
          name="description"
          content="Współfinansowanie ze środków Unii Europejskiej w ramach działania 'Wsparcie dla rozwoju lokalnego w ramach inicjatywy LEADER' - Stragona"
        />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <EuroSection />
    </>
  );
}
