import React from "react";
import styled from "styled-components";
import Logo22 from "../assets/logo22.png";
import EuroIMGTwo from "../assets/logotypy.jpg";
const EuroSection = () => {
  return (
    <Wrapper>
      <img src={EuroIMGTwo} className="euro_img" />
      <div>
        <p className="first">
          <b>
            „Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich:
            Europa inwestująca w obszary wiejskie”
          </b>
        </p>
        <p>
          Operacja pn. „Modernizacja zaplecza gastronomiczno-cateringowego w
          Restauracji Stragona” mająca na celu rozwój działalności firmy dzięki
          udoskonaleniu usług gastronomicznych współfinansowana jest ze środków
          Unii Europejskiej w ramach działania „Wsparcie dla rozwoju lokalnego w
          ramach inicjatywy LEADER” Programu Rozwoju Obszarów Wiejskich na lata
          2014-2020.
        </p>

        <p>
          Projekt dotyczył rozwoju firmy w zakresie działalności
          gastronomiczno-cateringowej w miejscowości Strzegom. Charakter
          działalności polegał na świadczeniu usług gastronomicznych (śniadania,
          obiady, kolacje) dla gości restauracji, a także dla przybywających
          turystów. Zakładano również nawiązanie współpracy z lokalnymi
          rolnikami i producentami oferując dania kuchni lokalnej bazujących na
          świeżych składnikach. Całkowicie nowatorskim podejściem jest wdrożenie
          do życia idei SlowFood i oparcie oferowanych dań o kuchnię tradycyjną
          i produkty lokalne. Dodatkowo odpowiednie zaplecze umożliwia
          przygotowywanie posiłków dla pracowników okolicznych zakładów i fabryk
          - planowane jest nawiązanie stałej współpracy z firmami działającymi w
          okolicy Strzegomia i Świdnicy. W chwili obecnej usługi gastronomiczne
          w obiekcie są świadczone jedynie w podstawowym zakresie, z uwagi na
          ograniczenia sprzętowe.
        </p>
        <p>Inwestycja polegała na zakupie:</p>
        <p>
          1) kompleksowego wyposażenia gastronomiczno-cateringowego do lokalu,
          dzięki czemu możliwe jest przygotowywanie posiłków,
        </p>
        <p>
          2) zakup instalacji fotowoltaicznej, która pozwoliła na zmniejszenie
          zużycia energii i zapewnienie równowagi energetycznej.
        </p>
        <p>Całkowity koszt projektu: 198 527,10</p>
        <p>Wartość dofinansowania: 125 000,00</p>
        <p>
          Operacja przyczynia się do realizacji Lokalnej Strategii Rozwoju
          Lokalnej Grupy Działania „Szlakiem Granitu” na lata 2014-2020.
        </p>
        <p style={{ textAlign: "center" }}>
          ----------------------------------------------------------------------------------------------------
        </p>
      </div>
      <img src={Logo22} className="euro_img" />
      <div>
        <p>
          <b>
            FIRMA GASTRONOMICZNO – HOTELARSKO – HANDLOWO - USŁUGOWA "STRAGONA"
            SPÓŁKA CYWILNA DARIUSZ PYLAK, PAWEŁ PYLAK
          </b>{" "}
          realizuje projekt pt. "Wdrożenie nowoczesnej technologii warzenia oraz
          innowacyjnego piwa przez Stragona s.c."
        </p>
        <p>
          Projekt realizowany jest przez firmę dotyczy wdrożenia nowoczesnej
          technologii warzenia oraz wprowadzenia do oferty innowacyjnego piwa
          bezchmielowego.
        </p>
        <p>
          Projekt ma polegać na zakupie specjalistycznego sprzętu produkcyjnego:
          - zakup urządzeń minibrowaru - zakup technologii wytwarzania piwa i
          praw do jej wykorzystywania.
        </p>
        <p>
          Efekty projekty obejmowały będą wprowadzenie produktu nieznanego i
          niestosowanego dotąd w Polsce, innowacyjnej technologii wytwarzania,
          wzrost przychodów z działalności oraz wzrost zatrudnienia. Projekt
          realizowany będzie w Strzegomiu.
        </p>
        <p>Termin realizacji to 01.12.2020-31.03.2023.</p>
        <p>Całkowity koszt projektu: 1 230 000 PLN</p>
        <p>Wartość dofinansowania: 400 000 PLN. </p>
        <p>
          Projekt realizowany ze środków Europejskiego Funduszu Rozwoju
          Regionalnego w ramach Regionalnego Programu Operacyjnego Województwa
          Dolnośląskiego na lata 2014-2020.
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-top:180px;
  
  .euro_img {
    margin-top: 32px;
    padding-bottom:32px;
    
  }
  flex-direction: column;
  background-color: #222c2b;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .first {
    margin-top: 32px;
  }
  img {
    margin-top: 100px;
    @media (max-width: 1000px) {
      margin-left: 18px;
      margin-right:18px;
      width: 360px;
    }
    width: 820px;
  }
  div {
    
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width:824px;
    p {
      @media (max-width: 1000px) {
       width:80%;
        
      }
      width: 100%;
      font-size: 14px;
      line-height: 1.65;
      color: white;
      margin 8px 32px;
      
    }
  }
`;

export default EuroSection;
